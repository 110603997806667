<template>
  <footer v-if="Footer">
    <v-container>
      <v-row>
        <v-col cols="12" md="10" offset-md="1" lg="9" offset-lg="2" class="border py-16">
          <v-row align="center">
            <v-col cols="12" md="5" class="mb-4 mb-md-0">
              <h2 class="h3 -emo-gradient">{{ Footer.footerTitle }}</h2>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="10" offset-md="1" lg="9" offset-lg="2" class="border pb-12">
          <p class="mt-4 mb-10 text-strong">Contact us</p>
          <v-row>
            <v-col
              v-for="headquarter in Footer.footerHeadquarters"
              :key="headquarter.id"
              cols="12"
              md="4"
              class="mb-10"
            >
              <HeadquarterResume  class="hq-resume" v-bind="headquarter" />
            </v-col>
          </v-row>
          <v-row class="mb-6">
            <v-col cols="6" class="social-icons">
              <a
                :href="Footer.footerLinkedinUrl"
                target="_blank"
                aria-label="Linkedin"
                rel="noopener"
                class="mr-4"
              >
                <LinkedinSvg />
              </a>
              <a
                :href="Footer.footerInstagramUrl"
                target="_blank"
                aria-label="Instagram"
                rel="noopener"
              >
                <InstagramSvg />
              </a>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="8" class="mb-4 mb-md-0">
              <p class="text-small">{{Footer.footerCopyrightText}}</p>
            </v-col>
            <v-col cols="12" md="4">
              <router-link :to="{ name: 'PrivacyPage' }" class="text-small mr-2">
                Privacy
              </router-link>
              <a href="https://report.whistleb.com/regi" target="_blank" class="text-small">
                Whistleblowing
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
import GetFooter from '@/graphql/GetFooter.gql'
import HeadquarterResume from '@/atoms/HeadquarterResume'
import LinkedinSvg from '@/assets/img/linkedin.svg'
import InstagramSvg from '@/assets/img/instagram.svg'

export default {
  name: 'Menu',

  components: {
    HeadquarterResume,
    LinkedinSvg,
    InstagramSvg
  },

  apollo: {
    Footer: {
      query: GetFooter,
      variables () {
        return {
          slug: 'globals'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.border {
  border-top: 1px solid $color-detail;
}

.hq-resume {
  @include breakpoint('md-and-up') {
    max-width: rem(250px);
  }
}

a {
  text-decoration: none;
  color: $color-text;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    color: $color-text-dark !important;
  }
}

svg.logo {
  width: 150px;
  height: auto;

  path {
    fill: $color-text-dark;
  }
}

.social-icons svg {
  width: 24px;
  height: 24px;
  fill: $color-text;
  transition: all 0.2s ease-in-out;

  &:hover {
    fill: $color-text-dark;
  }
}
</style>
